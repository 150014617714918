var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"profile_form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ORGANIZATION')} (*)`,"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"organization":_vm.customer.organization.id,"filterable":true,"showAll":false},on:{"organizationChanged":(organizationId) => {
          _vm.customer.organization.id = organizationId;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOCATIONS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.LOCATION')}`,"placeholder":_vm.$t('COMMON.LOCATION')}},[_c('locations-selector',{attrs:{"locations":_vm.customer.allowedLocations,"filterable":true,"showAll":false,"multiple":true,"organization":_vm.customer.organization.id},on:{"locationsChanged":(locations) => {
          _vm.customer.allowedLocations = locations;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.location}}),_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.TYPE')} (*)`,"placeholder":_vm.$t('COMMON.TYPE')},on:{"change":(value) => {
        _vm.onChangeCustomerType(value);
      }}},[_c('el-select',{attrs:{"name":"Type"},on:{"change":() => {
          _vm.onFormChanged();
        }},model:{value:(_vm.customer.customer_type),callback:function ($$v) {_vm.$set(_vm.customer, "customer_type", $$v)},expression:"customer.customer_type"}},[_c('el-option',{attrs:{"value":"INDIVIDUAL","label":_vm.$t('CUSTOMERS.CUSTOMER_TYPE_INDIVIDUAL')}}),_c('el-option',{attrs:{"value":"COMPANY","label":_vm.$t('CUSTOMERS.CUSTOMER_TYPE_COMPANY')}})],1)],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.customer_type}}),(_vm.customer.customer_type === _vm.CUSTOMER_TYPE_INDIVIDUAL)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.FIRSTNAME')} (*)`},on:{"change":() => {
            _vm.onFormChanged();
          }},model:{value:(_vm.customer.firstname),callback:function ($$v) {_vm.$set(_vm.customer, "firstname", $$v)},expression:"customer.firstname"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.firstname}})],1),_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.LASTNAME')} (*)`},on:{"change":() => {
            _vm.onFormChanged();
          }},model:{value:(_vm.customer.lastname),callback:function ($$v) {_vm.$set(_vm.customer, "lastname", $$v)},expression:"customer.lastname"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.lastname}})],1)]):_vm._e(),(_vm.customer.customer_type === _vm.CUSTOMER_TYPE_COMPANY)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.COMPANY_NAME')} (*)`},on:{"change":() => {
            _vm.onFormChanged();
          }},model:{value:(_vm.customer.company_name),callback:function ($$v) {_vm.$set(_vm.customer, "company_name", $$v)},expression:"customer.company_name"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.company_name}})],1)]):_vm._e(),_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.EMAIL')} (*)`},on:{"change":() => {
        _vm.onFormChanged();
      }},model:{value:(_vm.customer.email),callback:function ($$v) {_vm.$set(_vm.customer, "email", $$v)},expression:"customer.email"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.email}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.TYPE')}`}},[_c('el-select',{attrs:{"label":_vm.$t('COMMON.TYPE'),"placeholder":_vm.$t('COMMON.TYPE')},on:{"change":(type) => {
              _vm.customer.phone_type = type;
              _vm.onFormChanged();
            }},model:{value:(_vm.customer.phone_type),callback:function ($$v) {_vm.$set(_vm.customer, "phone_type", $$v)},expression:"customer.phone_type"}},_vm._l((_vm.phoneTypesOptions),function(value,key){return _c('el-option',{key:key,attrs:{"value":value,"label":_vm.$t(`COMMON.PHONE_TYPE_${value}`)}})}),1)],1)],1),_c('div',{staticClass:"col-7"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.PHONE')} (*)`}},[_c('phone-number-input',{attrs:{"phoneNumber":_vm.customer.phone,"required":true},on:{"phoneNumberChanged":(phone) => {
              _vm.customer.phone = phone;
              _vm.onFormChanged();
            }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.phone}})],1),_c('div',{staticClass:"col-3"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.EXTENSION')}`,"inputClasses":'extension-input'},on:{"change":() => {
            _vm.onFormChanged();
          }},model:{value:(_vm.customer.phone_extension),callback:function ($$v) {_vm.$set(_vm.customer, "phone_extension", $$v)},expression:"customer.phone_extension"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.phone_extension}})],1)]),_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.OTHER_PHONES')}`}},[_c('phone-numbers-selector',{attrs:{"phoneNumbers":_vm.customer.other_phones},on:{"phoneNumbersChanged":(phoneNumbers) => {
          _vm.customer.other_phones = phoneNumbers;
          _vm.onFormChanged();
        }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.other_phones}}),_c('div',{staticClass:"form-wrapper full mb-3"},[_c('addresses-selector',{on:{"addressInputChangeChanged":(values) => {
          _vm.customer = {
            ..._vm.customer,
            ...values,
          };
          _vm.onFormChanged();
        }}})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.COUNTRY')} (*)`,"placeholder":_vm.$t('COMMON.COUNTRY')}},[_c('country-selector',{attrs:{"country":_vm.customer.country,"filterable":true,"showAll":false},on:{"countryChanged":(country) => {
              _vm.customer.country = country;
              _vm.onFormChanged();
            }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.country}})],1),_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.STATE')} (*)`,"placeholder":_vm.$t('COMMON.STATE')}},[_c('state-selector',{attrs:{"country":_vm.customer.country,"state":_vm.customer.state,"filterable":true,"showAll":false},on:{"stateChanged":(state) => {
              _vm.customer.state = state;
              _vm.onFormChanged();
            }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.state}})],1),_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.CITY')} (*)`},on:{"change":() => {
            _vm.onFormChanged();
          }},model:{value:(_vm.customer.city),callback:function ($$v) {_vm.$set(_vm.customer, "city", $$v)},expression:"customer.city"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.city}})],1),_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ZIPCODE')} (*)`},on:{"change":() => {
            _vm.onFormChanged();
          }},model:{value:(_vm.customer.zipcode),callback:function ($$v) {_vm.$set(_vm.customer, "zipcode", $$v)},expression:"customer.zipcode"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.zipcode}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ADDRESS')} (*)`},on:{"change":() => {
            _vm.onFormChanged();
          }},model:{value:(_vm.customer.address),callback:function ($$v) {_vm.$set(_vm.customer, "address", $$v)},expression:"customer.address"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.address}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.TAGS')}`,"placeholder":`${_vm.$t('COMMON.TAGS')}`}},[_c('tags-selector',{attrs:{"tags":_vm.customer.tags,"disabled":!_vm.customer.organization.id,"organization":_vm.customer.organization.id},on:{"tagsChanged":(tags) => {
              _vm.customer.tags = tags;
              _vm.onFormChanged();
            }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.tags}})],1)]),_c('h2',[_vm._v(_vm._s(_vm.$t("COMMON.BILLING_ADDRESS")))]),_c('base-checkbox',{staticClass:"mb-3",on:{"input":_vm.applyCustomerDataToBilling},model:{value:(_vm.billingAddressSameAsCustomer),callback:function ($$v) {_vm.billingAddressSameAsCustomer=$$v},expression:"billingAddressSameAsCustomer"}},[_c('span',{staticClass:"form-control-label"},[_vm._v(" "+_vm._s(_vm.$t("CUSTOMERS.BILLING_INFORMATIONS_SAME_AS_CUSTOMER"))+" ")])]),(!_vm.billingAddressSameAsCustomer)?_c('billing-informations-form',{attrs:{"billingInformationsData":_vm.customer,"formErrors":_vm.formErrors,"type":_vm.customer.customer_type},on:{"onChangeBillingInformations":_vm.billingInformationsChanged}}):_vm._e(),_c('div',{staticClass:"my-4"},[_c('base-button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.customer.id ? _vm.$t("CUSTOMERS.EDIT_CUSTOMER") : _vm.$t("CUSTOMERS.ADD_CUSTOMER"))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }