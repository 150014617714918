<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">{{ $t("CUSTOMERS.ADD_CUSTOMER") }}</h3>
            </div>
            <div class="col-4 text-right">
              <base-button
                @click="goBack"
                type="button"
                class="btn btn-sm btn-primary"
              >
                {{ $t("COMMON.RETURN_TO_LIST") }}
              </base-button>
            </div>
          </div>
          <div class="card-body">
            <customer-form
              :loading="loading"
              :customerData="customer"
              :formErrors="formErrors"
              @customerSubmitted="handleSubmit"
              @formChanged="() => (alertLeave = true)"
            />
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultCustomer from "./defaultCustomer";
import CustomerForm from "./partials/CustomerForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    CustomerForm,
  },

  mixins: [alertLeave],

  data() {
    const customer = cloneDeep(defaultCustomer);
    return {
      customer: customer,
      formErrors: null,
      loading: false,
    };
  },

  created() {},

  methods: {
    goBack() {
      this.$router.push({ name: "List Customers" });
    },

    viewCustomer(customer) {
      this.alertLeave = false;
      this.$router.push({
        name: "View Customer",
        params: { id: customer.id },
      });
    },

    async handleSubmit(customer) {
      this.loading = true;
      const customerData = cloneDeep(customer);

      try {
        await this.$store.dispatch("customers/add", customerData);
        this.$notify({
          type: "success",
          message: this.$t("CUSTOMERS.CUSTOMER_ADDED"),
        });
        const customer = await this.$store.getters["customers/customer"];
        this.viewCustomer(customer);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
